import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from 'react-player'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';
import { useUserData } from '../../context/authContext'
import Vimeo from "@u-wave/react-vimeo";
import { useTranslation } from 'react-i18next';
const VideoContainer = ({ video }: any) => {
  const { userData } = useUserData()
  const [onOpen, setOnOpen] = useState<boolean>(false)
  const { t, i18n } = useTranslation();
  const [audio, setAudio] = useState<string>('pt')
  const [isImage, setIsImage] = useState<boolean>(true)
  console.log('video?.urlL[audio]', video
  )
  const config = {
    headers: { Authorization: `Bearer ${userData.token}` }
  }
  const [progress, setProgress] = React.useState<string>('');


  const handleWatchComplete = ({ played }: any) => {
    if (progress === `${Number(Number(played).toFixed(2)) * 100}%`) return;
    setProgress(`${Number(Number(played).toFixed(2)) * 100}%`)
    console.log('progress', Number(Number(played).toFixed(2)) * 100)
    api.put(`/videos/${video.id}?userId=${userData.user.id}`, {
      isReproduceFinished: played >= 0.7 && played <= 1.5 ? true : undefined,
      progress: `${Number(Number(played).toFixed(2)) * 100}%`
    }, config).then(() => {

    }).catch((error) => {
      console.log(error)
      // toast.error(error?.response?.data?.message ||
      //   'Ocorreu um erro', {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    })

  }

  const getAudio = () => {
    return video.urlL[audio]
  }




  return (
    <div className="video-container">
      <ToastContainer />
      <div className="embed-responsive embed-responsive-16by9">

        {Boolean(isImage && getAudio().length !== 0) ?
          (
            // ...props.style, textAlign: 'center'
            <div style={{ textAlign: 'center' }}>
              <img
                className={'imgggggg'}
                width={'100%'}
                style={{ width: '100%', height: 700 }}

                src={getAudio()}
                onError={() => {
                  setIsImage(false);
                }}
              />
            </div>
          ) : (
            <ReactPlayer
              url={getAudio()}
              height={700}
              width="100%"
              controls
              playing
              onProgress={handleWatchComplete}
            //onEnded={onFinishVideo}
            />
          )
        }


      </div>
      <br />
      <span onClick={() => setOnOpen(!onOpen)} className="audioContainer">
        {String(audio).toUpperCase()}
      </span>
      {onOpen && <div className="audioContainerCo"
        style={{ margin: 2, borderRadius: 10 }}>

        <a onClick={() => {
          setIsImage(true)
          setAudio('pt')
          setOnOpen(false)
        }}
          className="audioContainerC">
          PT
        </a>

        <a onClick={() => {
          setIsImage(true)
          setAudio('en')
          setOnOpen(false)
        }}
          className="audioContainerC">
          EN
        </a>

        <a onClick={() => {
          setIsImage(true)
          setAudio('es')
          setOnOpen(false)
        }}
          className="audioContainerC">
          ES
        </a>
      </div>}



    </div>
  );
}
export default VideoContainer;


// import React, { useState } from "react";
// import Vimeo from "@u-wave/react-vimeo";
// import Check from '../VideoContainer/Check';

// const VideoContainer = ({videoUrl, title, onProgress, isEbend}) => {  

//   console.log(id);
//     return (
//       <div className="video-container" style={{position: 'relative'}}>

//         <Check isEbend={isEbend}/>
//         <Vimeo
//           video={videoUrl}
//           height={700}
//           showByline
//           showTitle={title}
//           onProgress={onProgress}
//         />

//       </div>
//     );
//   }
//   export default VideoContainer;


// import Vimeo from "@u-wave/react-vimeo";
/* <Vimeo
 video={videoUrl}
 height={700}
 showByline
 showTitle={title}
 onEnd={() => myCallback()}
/> */


/* <iframe title={title} src={`https://player.vimeo.com/video/${videoUrl}`}  height="700" frameborder="0" allowfullscreen></iframe> */