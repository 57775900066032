import { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CompletedCourse from '../../components/CompletedCourse';
import Welcome from '../../components/Welcome';
import TitleContainer from '../../components/UI/TitleContainer';
import MainContainer from '../../components/UI/MainContainer';
import VideoContainer from '../../components/VideoContainer';
import Container from '../../components/UI/Container';
import StarRating from '../../components/StarRating';
import { useUserData } from '../../context/authContext';
import { useEventData } from '../../context/eventContext'
import api from '../../services/api';
import { useTranslation } from 'react-i18next';


const WatchVideo = ({ }) => {

    const { eventKey, id } = useParams<{ eventKey: string, id: string }>();
    const { userData } = useUserData()
    const { eventData } = useEventData()
    const [rating, setRating] = useState(3);
    const { t, i18n } = useTranslation();

    const handleChange = (value: any) => { setRating(value); }

    const [buttonCertificate] = useState(false);

    const [infoVideo, setInfoVideo] = useState<any>(null);

    // const token = window.localStorage.getItem('token');
    const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    }


    useEffect(() => {
        const loadVideo = () =>{
            api.get(`/videos/${eventKey}/${id}?userId=${userData.user.id}`, config)
            .then((response) => {
                // Pegando as informações dos Videos (Title, titulo, capa e url)
                setInfoVideo(response?.data);
            }).catch((error) => {
                console.log(error);
                loadVideo()
            })
        }
        loadVideo()
    }, []);

    return (
        <Container pageContainer isBackground={false}>
            <Header />
            <TitleContainer><span style={{ color: eventData.customization.fontColor }}>{eventData.name}</span></TitleContainer>

            {infoVideo && <MainContainer MainContainer>
                <div className="video-flex">
                    <VideoContainer video={infoVideo} />

                    <div className="video-side-options">
                        <div className="side-block">
                            <Link to={`/${eventKey}/home`} className="button-all-videos">{t('watch.allVideos')}</Link>
                        </div>

                        <Welcome description={infoVideo?.descriptionL ? infoVideo?.descriptionL[i18n.language] : infoVideo?.description} title={infoVideo?.titleL ? infoVideo?.titleL[i18n.language] : infoVideo?.title} />

                        <StarRating
                            video={infoVideo}
                            count={5}
                            size={30}
                            value={rating}
                            activeColor={'#fc0'}
                            inactiveColor={'#ddd'}
                            onChange={handleChange}
                        />

                        {buttonCertificate && (
                            <CompletedCourse />
                        )}

                    </div>
                </div>
            </MainContainer>}
            <Footer />
        </Container>
    );
}

export default WatchVideo;
