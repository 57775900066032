import React from 'react';


const IframeNotFound: React.FC  = () =>{
    return(
        <>
        <iframe style={{width: '100vw', height:'100vh'}}src="https://4yourlive.com.br/" frameBorder="0">

        </iframe>
        </>
    )
}
export default IframeNotFound;
