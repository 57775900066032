export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
        login: {
            forgotPassword: 'I forgot the password',
            requiredEmail: 'Email is required',
            format: 'Invalid email address format',
            character8: 'Password must contain up to 8 characters',
            enter: 'LOG IN',
            entering:'ENTERING...',
            failed: 'Unable to login, please try again.',
            firtAccess: 'FIRST ACCESS',
            code: 'Enter your access code',
            continue: 'CONTINUE'
        },
        header:{
            editProfile: 'Edit profile',
            exit:'Go out',
        },
        home:{
            gratters: 'Gratters',
            completed:'Concluded',
            certificate: 'Request my certificate',
            congratulations:'You have completed the SPeCiAL Program and are prepared to provide the best care to your patients.'
        },
        watch:{
          allVideos:'All videos'
        },
        starRating:{
            commentsTitle: 'Comments',
            commentsBody: 'Share with us your comments, questions and experiences on this topic.',
            comment: 'Comment',
            success:'Comment sent successfully!',
            error: 'Please enter a comment.'
        },
        profile:{
            title:'Profile Editing',
            dados: 'General Profile Data',
            update:'Update data',
            updating: 'Updating ...',
            name:'Enter a name with a minimum of 3 characters and a maximum of 30 characters',
            profile: 'Profile picture'
        },
        certificate:{
            name:'Certificate',
            videos:'You have completed all videos.',
            botao:'Click on the button below to request your certificate of participation.',
            n: 'Your certificate is not yet available.',
            n2: 'Watch all videos to ensure yours.'
        },
        register:{
            name:'Enter your details to complete the registration:',
            estado: 'State*',
            programa: 'How did you find out about the program?',
            inscribed: 'I m already registered',
            load: 'Register',
            loading: 'Signing up ...',
            failed: 'It was not possible to register, try again.',
            e3: 'The name must be at least 3 characters long',
            e30: 'The name must be 30 characters at most',
            obrName: 'Name is required',
            formatEmail:'Invalid email address format',
            obrEmail:'E-mail is required',
            obrPassword: 'Password is required',
            hardPass: 'Enter a strong password',
            ambos: 'Both passwords need to be the same',
            campos: 'This field is mandatory',
            invalid: "Invalid selection"
        },
        form:{
            title: 'SPeCiAL program | Patient Safety in Surgeries in Latin America'
        }
    }
}