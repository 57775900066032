import React from 'react';

interface Props {
    title?: string;
    description?: string;
}
const Welcome: React.FC<Props> = ({ title, description }) => {

    return (
        <div className="side-block">
            <h3>{title}</h3>
            <p>{description}</p>
            <hr style={{ height: '2px' }} />
        </div>
    );
}

export default Welcome;
