import React from 'react';
import { useEventData } from '../../../context/eventContext'
const TitleContainer: React.FC = ({ children }) => {
  const { eventData } = useEventData()
  return (
    <section style={{ backgroundImage: `linear-gradient(to right, ${eventData.customization.backgroundHeaderGradientColor1} , ${eventData.customization.backgroundHeaderGradientColor2})` }} className="title-container gradientLine">
      <p style={{ color: eventData.customization.fontColor }}>
        {children}
      </p>
    </section>
  );
}

export default TitleContainer;
