import React from 'react'
import { useTranslation } from 'react-i18next';

const CompletedCourse = ({}) => {  
    const { t, i18n } = useTranslation();

    return (
        <div className="side-block" id="buttonCertificate" style={{display: 'block'}}>
            <div className="videos-get-certificate" style={{width: '100%'}}>
                <h4>{t('home.gratters')}</h4>
                <p className="congratulations">{t('home.congratulations')}</p>
                <p style={{paddingTop: '15px'}}><a href="get-certificate-survey.php" className="get-certificate-button">{t('home.certificate')}</a></p>
            </div>
        </div>
    );
  }
  
  export default CompletedCourse;
  