import React from 'react';
import { Redirect, Route, } from 'react-router-dom';
import { useUserSaved } from '../context/authContext';
import { useBeLoaded } from '../context/eventContext'

const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {
    const { userSaved } = useUserSaved();
    const { beLoaded } = useBeLoaded()
    const eventKey: string = rest.computedMatch.params.eventKey;

    const _renderComponent = () => {
        if (!beLoaded) {
            return <Redirect to={`/${eventKey}/loading`} />
        }
        return userSaved ?
            <Component {...rest} />
            :
            <Redirect to={`${eventKey.length === 0 ? '' : `${eventKey}/`}login`} />
    }

    
    return <Route {...rest} render={_renderComponent} />
}

export default PrivateRoute;


