import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCxa1wDZMi_XWSyDU5JvZUGZuYVpVxGn5U",
  authDomain: "rstcom-videoteca.firebaseapp.com",
  projectId: "rstcom-videoteca",
  storageBucket: "rstcom-videoteca.appspot.com",
  messagingSenderId: "819177640833",
  appId: "1:819177640833:web:ddb6362450f1074adcce80",
  measurementId: "G-YJW62BBYNM"
}

firebase.initializeApp(config)

export default config;
