import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Container from '../../components/UI/Container';
import MainContainer from '../../components/UI/MainContainer';
import TitleContainer from '../../components/UI/TitleContainer';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { useEventData } from '../../context/eventContext'
import * as Yup from "yup";

import api from '../../services/api';

import { useUserData, useUserSaved } from '../../context/authContext'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const LoginDefault: React.FC = () => {
  const { i18n, t } = useTranslation()
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('login.format'))
      .required(t('login.requiredEmail')),
  });
  const history = useHistory();
  const { eventKey } = useParams<{ eventKey: string }>();
  const { setUserSaved } = useUserSaved()
  const [isSubmitting, SetisSubmitting] = useState(false);
  const { eventData } = useEventData()

  const { userData, setUserData } = useUserData();
  useEffect(() => {
    if (Boolean(i18n.language !== 'pt') && Boolean(i18n.language !== 'es') && Boolean(i18n.language !== 'en')) {
        i18n.changeLanguage('pt')
    }
}, [])


  // Instância do i18n

  function handleChangeLanguage(language: string) {
    // Trocando o idioma na chamada da função
    i18n.changeLanguage(language)
  }
  const selectedLanguage = i18n.language // Idioma selecionado


  const [initialValues, SetInitialValues] = useState({
    email: '',
    password: '',
    eventKey: `${eventKey}`
  });

  const handleSubmit = async (values: any, actions: any) => {

    if (isSubmitting) return;
    SetisSubmitting(true);
    actions.setSubmitting(true);
    values.email = String(values.email).toLowerCase()
    try {
      console.log('handleSubmit', values);
      if (!eventData.isAvailablePassword) {
        delete values.password
      } else {
        if (String(values.password).length < 8) {
          return toast.error(t('login.character8') ||
            t('login.failed'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        }

      }


      const response = await api.post('/sessions/login', values)
      const { data } = response;
      if (data) {
        setUserData(data);
        setUserSaved(true);
        localStorage.setItem('@userData', JSON.stringify(data))
        history.push(`/${eventKey}/home`);
      }
      actions.resetForm();
      SetInitialValues({ ...initialValues, email: '', password: '' })

    } catch (error) {
      console.log('handleSubmit', { error, values });
      toast.error(error?.response?.data?.message ||
        t('login.failed'), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } finally {
      SetisSubmitting(false);
      actions.setSubmitting(false);
    }


  };

  // useEffect(() => {
  //   SetInitialValues({...initialValues, eventKey:eventKey});
  // }, [eventKey]);

  return (
    <Container isBackground pageContainer={true}>
      <Header />
      <TitleContainer />

      <MainContainer MainContainer={true}>
        <div style={{ flexDirection: 'column' }} className="login-container">
          <div className="logo-form">
            <img src={eventData.customization.banner} alt="" />
          </div>

          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={RegisterSchema}>
            {({ handleChange, handleBlur, values, touched, errors }) => (
              <Form>
                <div className="form-group">
                  <Field
                    type="text"
                    name="email"
                    disabled={isSubmitting}
                    placeholder="Email*"
                    className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                      }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="email"
                    className="invalid-feedback"
                  />
                </div>

                {eventData.isAvailablePassword && <div className="form-group">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Senha*"

                    className={`form-control ${touched.password && errors.password ? "is-invalid" : ""
                      }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="password"
                    className="invalid-feedback"
                  />
                </div>}

                <ToastContainer />

                <div className="form-final-line" style={{ display: 'flex' }}>

                  <div>
                    <button style={{ backgroundColor: eventData.customization.buttonBackgroundColor, color: eventData.customization.fontColor }} data-aos="fade-up" type="submit" className="login-button button-login" disabled={isSubmitting}>
                      {isSubmitting ? t('login.entering') : t('login.enter')}
                    </button>
                  </div>

                  {/* <div >
                    <Link style={{
                      color: eventData.customization.buttonTextColor,
                      backgroundColor: 'transparent'
                    }} to="register" className="login-button button-register">PRIMEIRO ACESSO</Link>
                  </div> */}
                </div>
                {/* 
                <div style={{ padding: '17px 0' }}>
                  <Link style={{
                    color: eventData.customization.fontColor
                  }} to="forgot-password" className="form-password-reset">{t('login.forgotPassword')}</Link>
                </div> */}

              </Form>
            )}
          </Formik>

        </div>
      </MainContainer>
      <Footer />
    </Container>
  );
}

export default LoginDefault;
