export default {
  translations: { // Mesmo valor usado nas configurações (defaultNS)
    login: {
      forgotPassword: 'Eu esqueci a senha',
      requiredEmail: 'Email é obrigatório',
      format: 'Formato de endereço de e-mail inválido',
      character8: 'A senha deve conter até 8 caracteres',
      entering: 'ENTRANDO...',
      enter: 'ENTRAR',
      failed: 'Não foi possível realizar o login, tente novamente.',
      firtAccess: 'PRIMEIRO ACESSO',
      code: 'Insira o seu código de acesso',
      continue: 'CONTINUAR'
    },

    header: {
      editProfile: 'Editar perfil',
      exit: 'Sair',
    },
    home: {
      gratters: 'Parabéns',
      completed:'Concluído',
      certificate: 'Solicitar meu certificado',
      congratulations:'Você concluiu o Programa SPeCiAL e está preparado para oferecer o melhor cuidado aos seus pacientes.'
    },
    watch:{
      allVideos:'Todos os vídeos'
    },
    starRating:{
      commentsTitle: 'Comentários',
      commentsBody: 'Compartilhe conosco seus comentários, dúvidas e experiências sobre esse tema.',
      comment: 'Comentar',
      success:'Comentário enviado com sucesso!',
      error: 'Por favor, insira um comentário'
    },
    profile:{
      title:'Edição de Perfil',
      dados: 'Dados gerais do perfil',
      update:'Atualizar Dados',
      updating: 'Atualizando ...',
      name:'Insira um nome com no minímo 3 caracteres e no máximo 30 caracteres',
      profile: 'Foto de perfil'
    },
    certificate:{
      name:'Certificado',
      videos:'Você concluiu todos os vídeos.',
      botao:'Clique no botão abaixo para solicitar o seu certificado de participação.',
      n: 'Seu certificado ainda não está disponível.',
      n2: 'Assista todos os vídeos para garantir o seu.'
    },
    register:{
      name:'Insira seus dados para finalizar o cadastro:',
      estado: 'Estado*',
      programa: 'Como conheceu o programa?',
      inscribed: 'Já sou inscrito',
      load: 'Inscrever-se',
      loading: 'Cadastrando ...',
      failed: 'Não foi possivel realizar o cadastro, tente novamente.',
      e3: 'O nome deve ter no mínimo 3 caracteres',
      e30: 'O nome deve ter 30 caracteres no máximo',
      obrName: 'Nome é obrigatório',
      formatEmail:'Formato de endereço de e-mail inválido',
      obrEmail:'E-mail é obrigatório',
      obrPassword: 'Senha é obrigatório',
      hardPass: 'Digite uma senha forte',
      ambos: 'Ambas as senhas precisam ser iguais',
      campos: 'Este campo é obrigatório',
      invalid: "Seleção inválida"
    },
    form:{
      title: 'Programa SPeCiAL | Segurança do Paciente em Cirurgias na América Latina'
    }
  }
}