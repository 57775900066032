import axios from 'axios';


const debug = 'http://localhost:3120';
const emulator = 'http://localhost:3333/rstcom20/us-central1/api';
const sandbox = 'https://us-central1-sandbox-rstcom-videoteca.cloudfunctions.net/api';
const remote = 'https://us-central1-rstcom-videoteca.cloudfunctions.net/api';

let url = remote;

if (document.location.href.indexOf('admin-homologacao-videoteca.rstcom.stream') >= 0)
  url = sandbox;
else if (document.location.href.indexOf('localhost') >= 0) {
  url = debug;
} else if (document.location.href.indexOf('127.0.0.1') > -1) {
  url = remote;
}


const api = axios.create({
  baseURL: url,
});

export default api;
