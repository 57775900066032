import React, { useContext } from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Container from '../../components/UI/Container';
import TitleContainer from '../../components/UI/TitleContainer';
import MainContainer from '../../components/UI/MainContainer';
import { useCertificate, useEventData } from '../../context/eventContext'
import { useTranslation } from 'react-i18next';
import Certificate from '../../components/Certificate';

const GetCertificate = () => {
    const { isCertificate } = useCertificate()
    const { t, i18n } = useTranslation();
    const { eventData } = useEventData()

    return (
        <Container pageContainer isBackground={false}>
            <Header />
            <TitleContainer><span style={{ color: eventData.customization.fontColor }}>{t('certificate.name')}</span></TitleContainer>

            <MainContainer MainContainer>
                <div className="edit-main-container">

                    {isCertificate && eventData.isCertificate  ? (
                        <div>
                            <p className="grid-fix" style={{ order: 10 }}>&nbsp;</p>
                            <div className="get-certificado">
                                <h4>{t('home.gratters')}</h4>
                                <p>{t('certificate.videos')}</p>
                                <p>{t('certificate.botao')}</p>
                                <Certificate></Certificate>

                                {/* <p style={{paddingTop: '15px'}}><a className="get-certificate-button" href="">Solicitar meu certificado</a></p> */}
                            </div>
                            {/* <div>
                                <img src="" width="500px" />
                                <p style={{paddingTop: '15px'}}><a className="get-certificate-button" href="">Salvar Certificado</a></p>
                            </div>  */}
                        </div>
                    ) : (
                        <p>{t('certificate.n')}<br />
                        {t('certificate.n2')}</p>
                    )}


                </div>
            </MainContainer>


            <Footer />
        </Container>
    );
}

export default GetCertificate;

