import React from 'react';
import { useEventData } from '../../../context/eventContext'
interface Props {
    MainContainer: boolean;
    isLoginMin?: boolean;
}
const MainContainer: React.FC<Props> = ({ children, MainContainer, isLoginMin }) => {
    const { eventData } = useEventData()
    return (
        <section style={isLoginMin ? { height: '85vh', backgroundImage: `url(${eventData.customization.bannerlobby})` } : {}} className={MainContainer ? `main-container blue-background ${isLoginMin ? 'centerbackground' : ''}` : `main-container ${isLoginMin ? 'centerbackground' : ''}`}>
            {children}
        </section>
    );
}

export default MainContainer;
