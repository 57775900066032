import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUserSaved } from '../context/authContext';

import { useBeLoaded } from '../context/eventContext'
const PublicRoute: React.FC<any> = ({ component: Component, ...rest }) => {
    const { userSaved } = useUserSaved();
    const { beLoaded } = useBeLoaded()
    const eventKey: string = rest.computedMatch.params.eventKey;
    const _renderComponent = () => {
        if (!beLoaded) {
            return <Redirect to={`/${eventKey}`} />
        }
        return userSaved ?
            <Redirect to={{ pathname: `home`, state: { from: rest.location } }} />
            :
            <Component {...rest} />
    }
    return <Route {...rest} render={_renderComponent} />
}

export default PublicRoute;
