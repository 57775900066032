import { Link, useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { useUserData } from '../../context/authContext'

const Certificate = () => {
    const { eventKey } = useParams();
    const { userData } = useUserData()

    return (
        <iframe style={{ width: '100%', height: '8vh', marginLeft: '-5px', border: 0, overflowX: 'hidden', overflowY: 'hidden', backgroundColor: '#fff' }}
            src={`https://rstcom.live/certificates/videoteca/${eventKey}/cert.php?name=${btoa(userData.user.name)}`}>

        </iframe>
    );
};

export default Certificate;