import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Login from '../pages/Login';
import Register from '../pages/Register';
import RegisterCode from '../pages/RegisterCode';
import Home from '../pages/Home';
import WatchVideo from '../pages/WatchVideo';
import IframeNotFound from '../components/IframeNotFound'
import GetCertificate from '../pages/GetCertificate';
import EditProfile from '../pages/EditProfile';
import ForgotPassword from '../pages/ForgotPassword';

import NotFound from '../components/NotFound';
import Loading from '../pages/Loading'
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';




const Routes = () => {


    return (
        <Router>
            <Switch>
                <PublicRoute path="/:eventKey/login" exact component={Login} />
                <PublicRoute path="/:eventKey/loading" exact component={Loading} />
                <PublicRoute path="/:eventKey/register" exact component={Register} />
                {/* <PublicRoute path="/:eventKey/register-code" exact component={RegisterCode} /> */}
                <PublicRoute path="/:eventKey/forgot-password" exact component={ForgotPassword} />

                <PrivateRoute path="/:eventKey/home" exact component={Home} />
                <Route path="/:eventKey" exact component={Loading} />


                <PrivateRoute path="/:eventKey/watch-video/:id" exact component={WatchVideo} />
                <PrivateRoute path="/:eventKey/get-certificate" exact component={GetCertificate} />
                <PrivateRoute path="/:eventKey/editprofile" exact component={EditProfile} />
                <Route component={IframeNotFound} />
            </Switch>
        </Router>

    );
};

export default Routes;