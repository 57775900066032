import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import { useBeLoaded, useEventData } from '../../context/eventContext';
import { useUserSaved, useUserData, UserData } from '../../context/authContext'
import api from '../../services/api';
import { useTranslation } from 'react-i18next';

const Loading: React.FC = () => {
    const { eventKey } = useParams<{ eventKey: string }>()
    const { setBeLoaded } = useBeLoaded()
    const { setEventData } = useEventData()
    const { userData, setUserData } = useUserData()
    const { t, i18n } = useTranslation();
    const { userSaved, setUserSaved } = useUserSaved()
    const [numberCountCatch, setNumberCountCatch] = useState<number>(0)
    const history = useHistory()

    useEffect(()=>{
        i18n.changeLanguage('pt')
    },[])

    useEffect(() => {
        const load = async () => {
            try {
                if (eventKey) {
                    api.get(`/event/${eventKey}`).then(res => {
                        setEventData({
                            ...res.data, customization: {
                                ...res.data.customization,
                                theme_color: res.data.customization.theme_color || "#ED1C24"
                            }
                        })
                        setBeLoaded(true);
                        const userLocalStorage = localStorage.getItem('@userData')
                        if (userLocalStorage) {
                            const dataUser: UserData = JSON.parse(userLocalStorage)
                            const config = {
                                headers: { Authorization: `Bearer ${dataUser.token}` }
                            }
                            api.get(`/sessions/keep-alive/${dataUser.user.id}/${res.data.id}`, config).then(ress => {
                                if (ress.data?.token) {
                                    setUserData({ user: dataUser.user, token: ress.data.token })
                                    setUserSaved(true)
                                    return history.replace(`/${eventKey}/${'home'}`)
                                } else {
                                    api.get(`/sessions/logout/${dataUser.user.id}`, config).finally(() => {
                                        return history.replace(`/${eventKey}/${'login'}`)
                                    })
                                }
                            }).catch((err => {
                                console.log(err)
                                api.get(`/sessions/logout/${dataUser.user.id}`, config).finally(() => {
                                    setUserSaved(false)
                                    localStorage.clear()
                                    return history.replace(`/${eventKey}/${'login'}`)
                                })
                            }))
                        }
                        else {
                            return history.replace(`/${eventKey}/${'login'}`)
                        }
                    }).catch((err: any) => {
                        console.log(err)
                        //@ts-ignore
                        // if (err?.response?.status == 404) {
                        //     history.replace(`/`)
                        // } else {
                        //     load();
                        // }
                        load();
                    })
                }
            } catch (error) {
                console.log('error', error)
                setUserSaved(false)
                return history.replace(`/`)
            }
        }
        load()
    }, [])


    return (
        <>
            <div className={styles.bodys}>
                <div className={styles.loading}>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                </div>
            </div>
        </>
    )
}
export default Loading;