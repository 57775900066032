export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
        login: {
            forgotPassword: 'Olvidé la contraseña',
            requiredEmail: 'correo electronico es requerido',
            format: 'Formato de dirección de correo electrónico no válido',
            character8: 'La contraseña debe contener hasta 8 caracteres',
            entering: 'ENTRANDO...',
            enter: 'INICIAR SESIÓN',
            failed: 'No se puede iniciar sesión, inténtelo de nuevo.',
            firtAccess: 'PRIMER ACCESO',
            code: 'Introduzca su código de acceso',
            continue: 'CONTINUAR'

        },
        header: {
            editProfile: 'Editar perfil',
            exit: 'Salir',
        },
        home: {
            gratters: 'Felicitaciones',
            completed:'Concluido',
            certificate: 'Solicita mi certificado',
            congratulations:'Usted ha completado el Programa SPeCiAL y está preparado para proporcionar la mejor atención a sus pacientes.'
        },
        watch:{
          allVideos:'Todos los vídeos'
        },
        starRating:{
            commentsTitle: 'Comentarios',
            commentsBody: 'Comparte con nosotros tus comentarios, preguntas y experiencias sobre este tema.',
            comment: 'Comentario',
            success:'Comentario enviado con éxito!',
            error: 'Por favor ingrese un comentario.'
        },
        profile:{
            title:'Edición de perfil',
            dados: 'Datos de perfil general',
            update:'Actualizar datos',
            updating: 'Actualizando ...',
            name:'Ingrese un nombre con un mínimo de 3 caracteres y un máximo de 30 caracteres',
            profile: 'Foto de perfil'
        },
        certificate:{
            name:'Certificado',
            videos:'Has completado todos los videos.',
            botao:'Haga clic en el botón de abajo para solicitar su certificado de participación.',
            n: 'Tu certificado aún no está disponible.',
            n2: 'Mira todos los videos para asegurarte el tuyo.'
        },
        register:{
            name:'Ingrese sus datos para completar el registro:',
            estado: 'Estado*',
            programa: 'Cómo se enteró del programa?',
            inscribed: 'Ya estoy registrado',
            load: 'Regístrate',
            loading: 'Registrarse ...',
            failed: 'No fue posible registrarse, inténtelo de nuevo.',
            e3: 'El nombre debe tener al menos 3 caracteres',
            e30: 'El nombre debe tener 30 caracteres como máximo',
            obrName: 'Se requiere nombre',
            formatEmail:'Formato de dirección de correo electrónico no válido',
            obrEmail:'Se requiere correo electrónico',
            obrPassword: 'Se requiere contraseña',
            hardPass: 'Escriba una contraseña segura',
            ambos: 'Ambas contraseñas deben ser iguales',
            campos: 'Este campo es obligatorio',
            invalid: "Selección no válida"
        },
        form:{
            title: 'Programa SPeCiAL | Seguridad del paciente en cirugías en América Latina'
        }
        
    }
}