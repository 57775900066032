import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';

import { useUserData } from '../../context/authContext'
import { useEventData } from '../../context/eventContext'
import './StarRating.css';

import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StarRating = ({ video, count, value, inactiveColor = '#ddd', size = 24, activeColor = '#f00', onChange }) => {

  const { id } = useParams();
  const { eventKey } = useParams();
  const { userData } = useUserData()
  const { t, i18n } = useTranslation();

  const { eventData } = useEventData()
  const stars = Array.from({ length: count }, () => '🟊')
  const handleChange = (value) => {
    onChange(value + 1);
  }




  const [textarea, setTextarea] = useState("");

  //const token = window.localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${userData.token}` }
  }

  const CreateComment = (event) => {
    event.preventDefault();

    api.post(`/comments/`, {

      participantId: userData.user.id,
      videoId: video.id,
      messageComment: textarea,
      starRate: value,

    }, config)
      .then((response) => {
        console.log(response.messageComment);

        toast.success(t('starRating.success'), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTextarea("");

      }).catch((error) => {
        console.log(error);
        toast.error(t('starRating.error'), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  }


  if (!eventData.isComment) {
    return <></>
  }
  return (
    <div className="side-block" id="avaliacao">
      <h3 className="video-header-comment">{t('starRating.commentsTitle')}</h3>
      <p style={{ paddingBottom: '10px' }}>{t('starRating.commentsBody')}</p>
      <div className="estrelas" data-children-count={6}>

        {/* <div>
            {stars.map((s, index) => {
              let style = inactiveColor;
              if (index < value) {
                style=activeColor;
              }
              return (
                <span className={"star"}
                  key={index}
                  style={{color: style, width:size, height:size, fontSize: size}}
                  onClick={()=>handleChange(index)}>{s}</span>
              )
            })}
          </div> */}
      </div>

      <ToastContainer />
      <p style={{ paddingBottom: '10px' }} data-children-count={1}>

        <textarea id="comment" rows={4} value={textarea}
          style={{ width: '100%', resize: 'none', borderRadius: '7px', padding: '10px' }} defaultValue={""}
          onChange={e => setTextarea(e.target.value)}
        />

      </p>
      <p align="right"><a href="#" className="button-post-comment" id="btnSubmit" onClick={CreateComment}>{t('starRating.comment')}</a></p>

    </div>
  )
}

export default StarRating;
