import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import IconQRCode from '../../assets/images/qrcode.png'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Container from '../../components/UI/Container';
import MainContainer from '../../components/UI/MainContainer';
import TitleContainer from '../../components/UI/TitleContainer';
import { useEventData } from '../../context/eventContext'
import api from '../../services/api';
import { useToasts } from 'react-toast-notifications'
import { useUserData, useUserSaved } from '../../context/authContext'
import LoginDefault from '../LoginDefault';
import Favicon from 'react-favicon'
import logoCathim from '../../assets/images/logo-cathim.png';

interface FormInput {
  email: string;
  eventKey: string;
  password?: string;
}

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';



const Login: React.FC = (props) => {
  const history = useHistory();
  const { eventKey } = useParams<{ eventKey: string }>();
  const { setUserSaved } = useUserSaved()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { eventData } = useEventData()
  const { addToast } = useToasts()


  const { userData, setUserData } = useUserData();

  const [initialValues, setInitialValues] = useState<FormInput>({
    email: '',
    password: '',
    eventKey: `${eventKey}`
  });

  const handleSubmit = async () => {
    const values = initialValues;
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      console.log('handleSubmit', values);
      if (!eventData.isAvailablePassword) {
        delete values.password
      } else {
        if (String(values.password).length < 8) {
          return addToast("A senha deve conter até 8 caracteres" ||
            'Nao foi possivel realizar o login, tente novamente.', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      }

      const response = await api.post('/sessions/login', values)
      const { data } = response;
      if (data) {
        setUserData(data);
        setUserSaved(true);
        localStorage.setItem('@userData', JSON.stringify(data))
        history.push(`/${eventKey}/home`);

      }

    } catch (error) {
      console.log('handleSubmit', { error, values });

      return addToast(error?.response?.data?.message ||
        'Nao foi possivel realizar o login, tente novamente.', {
        appearance: 'error',
        autoDismiss: true,
      })

    } finally {
      setIsSubmitting(false);
    }

  };

  // useEffect(() => {
  //   SetInitialValues({...initialValues, eventKey:eventKey});
  // }, [eventKey]);
  if (eventData.key !== 'galeriacathim') {
    return <LoginDefault {...props} />
  }
  return (
    <Container isBackground={false} pageContainer={true}>
      <Favicon url={eventData.customization?.favicon || 'https://firebasestorage.googleapis.com/v0/b/rstcom-videoteca.appspot.com/o/favicon.png?alt=media&token=884be2b1-f522-46c3-8502-885a2591da85'} />
      <Header />
      <TitleContainer />

      <MainContainer isLoginMin={true} MainContainer={true}>
        <div style={{ alignItems: 'flex-end', }} className="login-container">

          <div className='metadeView'>
            <div className="logoCathim">
              <img src={logoCathim} width="150" style={{ paddingBottom: 10 }}></img>
            </div>
            <input
              type="text"
              placeholder='E-mail'
              disabled={isSubmitting}
              onKeyPress={(e) => {
                if (e.keyCode == 13 || e.which == 13) {
                  handleSubmit()
                }
              }}
              className='inputslogins'
              value={initialValues.email}
              onChange={(e) => setInitialValues({ ...initialValues, email: e.target.value })}
            />

            <div className='rowbuttonview'>
              <span onClick={handleSubmit} className='buttonnlogin'>
                <span className='buttonnloginText'>{isSubmitting ? 'AGUARDE...' : 'ENTRAR'}</span>
              </span>
            </div>

            <span className='textInformations'>
              A partir de 14 de maio, você pode conferir todo o conteúdo do CATHIM
              também no TakedaPro, nosso site de conteúdo e
              informações feitas para dispensadores e prescritores.
              <br />
              Para acessar, digite o endereço no seu navegador
              ou escaneie o QR Code abaixo:
            </span>
            <div className='viewcontaiqrcodeweb'>
              <span className='textwebsitetakeda'>www.takedapro.com</span>
              <img className='qrcodeimg' src={IconQRCode} alt="" />
            </div>

            <span style={{ width: '100%', textAlign: 'left' }} className='capromText'>
              C-APROM/BR/FEI/0056 – Abril/2021
              <br />
              Material destinado à dispensadores e prescritores habilitados.
            </span>

          </div>
          <div className='footerviewlogincontainer'>

          </div>
        </div>

      </MainContainer>
      <Footer />
    </Container>
  );
}

export default Login;
