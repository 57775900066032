import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { useHistory, useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from '../../components/UI/Container';
import MainContainer from '../../components/UI/MainContainer';
import TitleContainer from '../../components/UI/TitleContainer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { useUserData, UserData } from '../../context/authContext'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const optionsUF = [

    { id: 'AC', title: 'Acre' },
    { id: 'AL', title: 'Alagoas' },
    { id: 'AP', title: 'Amapa' },
    { id: 'AM', title: 'Amazonas' },
    { id: 'BA', title: 'Bahia' },
    { id: 'CE', title: 'Ceará' },
    { id: 'DF', title: 'Distrito Federal' },
    { id: 'ES', title: 'Espirito Santo' },
    { id: 'GO', title: 'Goiás' },
    { id: 'MA', title: 'Maranhão' },
    { id: 'MT', title: 'Mato Grosso' },
    { id: 'MS', title: 'Mato Grosso do Sul' },
    { id: 'MG', title: 'Minas Gerais' },
    { id: 'PA', title: 'Pará' },
    { id: 'PB', title: 'Paraíba' },
    { id: 'PR', title: 'Paraná' },
    { id: 'PE', title: 'Pernambuco' },
    { id: 'PI', title: 'Piauí' },
    { id: 'RJ', title: 'Rio de Janeiro' },
    { id: 'RN', title: 'Rio Grande do Norte' },
    { id: 'RS', title: 'Rio Grande do Sul' },
    { id: 'RO', title: 'Rondônia' },
    { id: 'RR', title: 'Roraima' },
    { id: 'SC', title: 'Santa Catarina' },
    { id: 'SP', title: 'São Paulo' },
    { id: 'SE', title: 'Sergipe' },
    { id: 'TO', title: 'Tocantins' },
];

const reference = [
    { id: 'Congressos', title: 'Congressos' },
    { id: 'Redes sociais', title: 'Redes sociais' },
    { id: 'Amigos', title: 'Amigos' },
    { id: 'Colegas de profissão', title: 'Colegas de profissão' },
];


interface Props {
    password?: any;
}
const SectionRegister: React.FC<Props> = ({ password }) => {

    const history = useHistory();
    const { eventKey } = useParams<{ eventKey: string }>();
    const [isSubmitting, SetisSubmitting] = useState<boolean>(false);

    const { userData, setUserData } = useUserData();
    const { t, i18n } = useTranslation();

    const RegisterSchema = Yup.object().shape({

        name: Yup.string()
            .min(5, t('register.e3'))
            .max(30, t('register.e30'))
            .required(t('register.obrName')),
    
        email: Yup.string()
            .email(t('register.formatEmail'))
            .required(t('register.obrEmail')),
    
        password: Yup.string()
            .required(t('register.obrPassword'))
            .matches(/^(?=.*[A-Za-z])(?=.*\d)[\w\W]{8,100}$/, `${t('register.hardPass')}. Ex: Nbb_885522`)
            .min(8)
            .matches(RegExp("(.*[a-z].*)"), "Lowercase")
            .matches(RegExp("(.*[A-Z].*)"), "Uppercase")
            .matches(RegExp("(.*\\d.*)"), "Number"),
    
        changepassword: Yup.string().required(t('register.obrPassword'))
            .when("password", {
                is: (val: any) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    t('register.ambos')
                )
            }),
    
        fields: Yup.object().shape({
            specialty: Yup.string().required(t('register.campos')),
            instituicao: Yup.string().required(t('register.campos')),
            state: Yup.string()
                .oneOf(optionsUF.map(opt => opt.id), t('register.invalid'))
                .required(t('register.campos')),
            city: Yup.string().required(t('register.campos')),
            program: Yup.string()
                .oneOf(reference.map(opt => opt.id), t('register.invalid'))
                .required(t('register.campos')),
        }),
    });
    
    

    const [initialValues, SetInitialValues] = useState({
        name: "",
        email: "",
        fields: {
            specialty: "",
            instituicao: "",
            state: "",
            city: "",
            program: "",


        },
        eventKey: `${eventKey}`,
        password: ""
    });

    const handleSigningUp = async (values: any, actions: any) => {

        if (isSubmitting) return;
        SetisSubmitting(true);
        try {
            console.log('handleSigningUp', values);
            values.email = String(values.email).toLowerCase()
            values.lang = i18n.language

            const response = await api.post('/participant', values)
            const { data } = response;

            if (data) {
                const datas: UserData = data
                setUserData(datas);
            }

            actions.resetForm();
            history.push(`/${eventKey}/home`);

        } catch (error) {
            console.log('handleSigningUp', { error, values });

            toast.error(error?.response?.data?.message ||
                t('register.failed'), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } finally {
            SetisSubmitting(false);
        }

        actions.setSubmitting(false);
    };

    useEffect(() => {

        SetInitialValues(prev => ({ ...prev, password, eventKey }));


    }, [password, eventKey]);


    return (

        <>
            <Container pageContainer={true}>
                <Header />
                <TitleContainer> </TitleContainer>
                <MainContainer MainContainer={true}>
                    <div style={{ display: 'block', maxWidth: '1000px', margin: '0 auto' }}>
                        <h2 style={{ marginBottom: '30px', marginLeft: '-15px' }}>{t('register.name')}</h2>
                        <Formik
                            name="contactForm" id="contact_form"
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={RegisterSchema}
                            onSubmit={handleSigningUp}
                        >
                            {({ touched, errors, isSubmitting }: any) => (
                                <Form>
                                    <div className="row">

                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder="Nome*"
                                                className={`form-control ${touched.name && errors.name ? "is-invalid" : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="name"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="email"
                                                placeholder="Email*"
                                                className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="email"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                type="password"
                                                name="password"
                                                placeholder="Senha*"
                                                className={`form-control ${touched.password && errors.password ? "is-invalid" : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="password"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                type="password"
                                                name="changepassword"
                                                placeholder="Connfirmar Senha*"
                                                className={`form-control ${touched.changepassword && errors.changepassword ? "is-invalid" : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="changepassword"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="fields[specialty]"
                                                placeholder="Profissão"
                                                className={`form-control ${touched?.fields?.specialty && errors?.fields?.specialty ? "is-invalid" : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="fields[specialty]"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="fields[instituicao]"
                                                placeholder="Hospital/Instituição que trabalha *"
                                                className={`form-control ${touched?.fields?.instituicao && errors?.fields?.instituicao ? "is-invalid" : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="fields[instituicao]"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                name="fields[state]"
                                                component="select"
                                                placeholder="Estado *"
                                                className={` form-control ${touched?.fields?.state && errors?.fields?.state ? "is-invalid" : ""
                                                    }`}
                                            >
                                                <option value="">{t('register.estado')}</option>
                                                {optionsUF.map((option) => (
                                                    <option value={option.id} key={option.id}>
                                                        {option.title}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                component="div"
                                                name="fields[state]"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="fields[city]"
                                                placeholder="Cidade *"
                                                className={`form-control ${touched?.fields?.city && errors?.fields?.city ? "is-invalid" : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="fields[city]"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                name="fields[program]"
                                                component="select"
                                                className={` form-control ${touched?.fields?.program && errors?.fields?.program ? "is-invalid" : ""
                                                    }`}
                                            >
                                                <option value="">{t('register.programa')}</option>
                                                {reference.map((option) => (
                                                    <option value={option.id} key={option.id}>
                                                        {option.title}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                component="div"
                                                name="fields[program]"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>

                                    <ToastContainer />

                                    <div className="form-final-line">

                                        <button style={{ marginLeft: '-15px' }} type="submit" className="btn btn-line" disabled={isSubmitting}>
                                            {isSubmitting ? t('register.loading') : t('register.load')}
                                        </button>

                                        <Link to={`login`} className="inscrito">{t('register.inscribed')}</Link>


                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </MainContainer>

                <Footer />
            </Container>
        </>
    );
}

export default SectionRegister;