
import ptBrTranslations from './pt-br'
import esEsTranslations from './es-es'
import enUsTranslations from './en-us'

export default {
  'pt': ptBrTranslations,  // Atente-se a usar as abreviaturas corretas
  'es': esEsTranslations,  // Atente-se a usar as abreviaturas corretas
  'en': enUsTranslations   // https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
}
