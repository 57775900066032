import { Link } from 'react-router-dom';

interface Props {
  type?: any;
  value?: string;
  href?: any;
}
export const Button: React.FC<Props> = ({ type, value, href }) => {
  return (
    <Link type={type} to={href} className="login-button">{value}</Link>
  );
};