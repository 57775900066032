import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import * as Yup from "yup";
import firebase from 'firebase';
import { v4 } from 'uuid';
import { useUserData } from '../../context/authContext'
import { useEventData } from '../../context/eventContext'
import Container from '../../components/UI/Container';
import MainContainer from '../../components/UI/MainContainer';
import TitleContainer from '../../components/UI/TitleContainer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useTranslation } from 'react-i18next';
import config from './config';

import api from '../../services/api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faUser, faUnlockAlt } from '@fortawesome/free-solid-svg-icons'



const EditProfile = () => {

    const history = useHistory();
    const { userData, setUserData } = useUserData()
    const { eventData } = useEventData()
    const { eventKey } = useParams();
    const [isSubmitting, SetisSubmitting] = useState(false);
    const [image, setImage] = useState();
    const [preview, setPreview] = useState();
    const fileInputRef = useRef();
    const { t, i18n } = useTranslation();


    
const RegisterSchema = Yup.object().shape({

    name: Yup.string()
        .min(5, t('profile.name'))
        .max(30, t('profile.name'))
        .required(t('profile.name')),

    email: Yup.string()
        // .required('E-mail é obrigatório')
        .email(t('login.format'))
});



    const [initialValues, SetInitialValues] = useState({
        name: userData.user.name,
        email: userData.user.email,
        eventKey: `${eventKey}`,
        avatar: '',
        oldPassword: "",
        newPassword: "",
    });

    const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    }

    async function upload(inputFile, eventKey) {
        if (typeof inputFile !== 'string' && inputFile.length) {
            const uploaded = await firebase
                .storage()
                .ref(`/videoteca/${eventKey}/${v4()}`)
                .put(inputFile[0]);
            return (await uploaded.ref.getDownloadURL()) || '';
        }

        return '';
    }


    const handleEditeProfile = async (values, actions) => {

        if (isSubmitting) return;
        SetisSubmitting(true);
        try {
            console.log('handleEditeProfile', values);

            if (image) {
                values.avatar = await upload(image, eventKey);
            }

            const response = await api.put(`/participant/${userData?.user?.id}`, values, config)
            const { data } = response;

            if (data) {
                setUserData(data)
            }
            history.push(`/${eventKey}/home`);

        } catch (error) {
            console.log('handleEditeProfile', { error, values });

            toast.error(error?.response?.data?.message ||
                t('login.failed'), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } finally {
            SetisSubmitting(false);
        }

        actions.setSubmitting(false);
    };


    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }

        SetInitialValues(prev => ({ ...prev, image }));
    }, [image]);



    return (
        <Container>
            <Header logado={true} />
            <TitleContainer><span style={{color: eventData.customization.fontColor}}>{t('profile.title')}</span></TitleContainer>
            <MainContainer maincontainer="maincontainer">
                <div className="edit-main-container">
                    <Formik
                        name="contactForm" id="contact_form"
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={RegisterSchema}
                        onSubmit={handleEditeProfile}
                    >
                        {({ formProps, touched, errors, isSubmitting, setFieldValue }) => (
                            <Form className="edit-general-info">

                                <div className="row">

                                    <div className="conatinerPicture">
                                        <FontAwesomeIcon icon={faUser} size="2x" color={eventData.customization.theme_color} />
                                        <h3>{t('profile.dados')}</h3>
                                    </div>
                                    <div className="form-group">
                                        <Field type="text"
                                            name="name"
                                            placeholder="Nome*"
                                            className={`form-control ${touched.name && errors.name ? "is-invalid" : ""
                                                }`} />

                                        <ErrorMessage
                                            component="div"
                                            name="name"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Field
                                            type="text"
                                            name="email"
                                            placeholder="Email*"
                                            disabled
                                            className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="email"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                </div>


                                {/* <div className="row">
                                    <div className="conatinerPicture">
                                        <FontAwesomeIcon icon={faImage} size="3x" color={customization.theme_color} />
                                        <h3>Foto de perfil</h3>
                                    </div>

                                    <div className="form-group">

                                        {preview ? (
                                            <img
                                                src={preview}
                                                style={{ objectFit: "cover" }}
                                                onClick={() => {
                                                    setImage(null);
                                                }}
                                            />
                                        ) : (
                                            <img
                                                className="buttonImageUpload"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    fileInputRef.current.click();
                                                }
                                                }
                                                src="http://specialsteris.com/beta/images/profiledefault.jpg" alt="" />
                                        )}
                                        <input
                                            name="fields[uploadAvatar]"
                                            type="file"
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                            accept="image/*"
                                            onChange={(event) => {
                                                const file = event.target.files[0];
                                                if (file && file.type.substr(0, 5) === "image") {
                                                    setImage(file);
                                                } else {
                                                    setImage(null);
                                                }
                                            }}
                                        />

                                    </div>

                                </div> */}

                                {/* <div className="row"> 
                                            <div className="conatinerPicture">
                                                <FontAwesomeIcon icon={faUnlockAlt} size="3x" color={customization.theme_color}/>
                                                <h3>Alteração de Senha</h3>
                                            </div>                                        
                                                <div className="form-group">
                                                    <Field
                                                        type="password"
                                                        name="oldPassword"
                                                        placeholder="Digite a sua senha antiga*"
                                                        className={`form-control ${touched.oldPassword && errors.oldPassword ? "is-invalid" : ""
                                                            }`}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="oldPassword"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <Field
                                                        type="password"
                                                        name="newPassword"
                                                        placeholder="Digite a sua nova senha*"
                                                        className={`form-control ${touched.newPassword && errors.newPassword ? "is-invalid" : ""
                                                            }`}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="newPassword"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <Field
                                                        type="password"
                                                        name="changepassword"
                                                        placeholder="Confirme a sua nova senha*"
                                                        className={`form-control ${touched.changepassword && errors.changepassword ? "is-invalid" : ""
                                                            }`}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="changepassword"
                                                        className="invalid-feedback"
                                                    />
                                                </div>                                                

                                        </div> */}

                                <ToastContainer />

                                <div className="form-final-line">

                                    <button style={{ marginLeft: '-15px' }} type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? t('profile.update') : 'profile.updating'}
                                    </button>


                                </div>

                            </Form>
                        )}
                    </Formik>

                </div>
            </MainContainer>
            <Footer />
        </Container>

    );
}

export default EditProfile;