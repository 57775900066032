import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useContext } from "react";
import './styles.css'
import Check from './Check';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Container from '../../components/UI/Container';
import TitleContainer from '../../components/UI/TitleContainer';
import MainContainer from '../../components/UI/MainContainer';
import api from '../../services/api';
import Favicon from 'react-favicon'
import { useUserData } from '../../context/authContext'
import { useTranslation } from 'react-i18next';
import { useEventData, useCertificate } from '../../context/eventContext'
interface Videos {
    id: string;
    createdAt: number;
    updatedAt: number;
    title: string;
    titleL?: any;
    url: string;
    urlL: any;
    cover: string;
    coverL: any;
    description?: string;
    descriptionL?: any;
    eventKey: string;
    eventId: string;
    isReproduceFinished: boolean;
}
const Home: React.FC = () => {

    const [infoVideo, setInfoVideo] = useState<Videos[]>([]);
    const { eventKey } = useParams<{ eventKey: string }>();
    const { isCertificate, setIsCertificate } = useCertificate()
    const { t, i18n } = useTranslation();

    const { userData } = useUserData();
    const { eventData } = useEventData()


    useEffect(() => {
        if (Boolean(i18n.language !== 'pt') && Boolean(i18n.language !== 'es') && Boolean(i18n.language !== 'en')) {
            i18n.changeLanguage('pt')
        }
    }, [])

    // const token = window.localStorage.getItem('token');
    const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    }

    useEffect(() => {
        const load = () =>{
            api.get(`/videos/${eventKey}?userId=${userData.user.id}`, config)
            .then((response) => {
                // Pegando as informações dos Videos (Title, titulo, capa e url)
                setInfoVideo(response?.data.videos);
                console.log(response?.data.videos)
                setIsCertificate(response.data.isFinishTotalVideos)
            }).catch((error) => {
                console.log(error);
                load()
            })
        }
        load()
    }, []);

    // const isCheckedId = idChecked? Number.parseInt(idChecked, 10) : null;


    const getTitleSubTitle = (): string => {
        if (i18n.language === 'pt') {
            return eventData?.titleSubTitle?.pt || ''
        }
        if (i18n.language === 'en') {
            return eventData?.titleSubTitle?.en || ''
        }
        if (i18n.language === 'es') {
            return eventData?.titleSubTitle?.es || ''
        }

        return eventData?.titleSubTitle?.pt || ''
    }



    return (
        <Container pageContainer={true}>
            <Favicon url={eventData.customization?.favicon || 'https://firebasestorage.googleapis.com/v0/b/rstcom-videoteca.appspot.com/o/favicon.png?alt=media&token=884be2b1-f522-46c3-8502-885a2591da85'} />
            <Header />
            <TitleContainer><span style={{ color: eventData.customization.fontColor }}>{eventData.name}</span></TitleContainer>

            <MainContainer MainContainer>
                {getTitleSubTitle() && <div style={{ backgroundColor: eventData.customization.colorBackSubTitle || '#faebd6' }} className='virewew'>
                    <span dangerouslySetInnerHTML={{ __html: getTitleSubTitle() }} style={{ color: eventData.customization.colorSubTitle || '#000' }} className='virewews' />
                </div>}
                <br />
                {isCertificate && eventData.isCertificate &&
                    <div className="videos-get-certificate">
                        <h4>{t('home.gratters')}</h4>
                        <p>{t('home.completed')} - {eventData.name}</p>
                        <p style={{ paddingTop: '15px' }}><Link to={`/${eventKey}/get-certificate`} className="get-certificate-button">{t('home.certificate')}</Link></p>
                    </div>
                }


                <div className="videos-panel0">
                    {infoVideo.map((InfoIframe: Videos) => (
                        <Link to={{ pathname: `watch-video/${InfoIframe.id}` }} className="container-card" key={InfoIframe.id}>
                            <div className="column">
                                <div className="post-module">
                                    <div className="thumbnail">
                                        <Check isEbend={InfoIframe.isReproduceFinished} />
                                        <img src={InfoIframe?.coverL ? InfoIframe?.coverL[i18n.language] : InfoIframe?.cover} />
                                    </div>
                                    <div className="post-content">
                                        <h1 className="title">{InfoIframe?.titleL ? InfoIframe?.titleL[i18n.language] : InfoIframe?.title}</h1>
                                        <p className="description">{InfoIframe?.descriptionL ? InfoIframe?.descriptionL[i18n.language] : InfoIframe?.description}</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>


            </MainContainer>

            <Footer />
        </Container>
    );
}

export default Home;
