import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import { useParams } from 'react-router-dom'
interface UserData {
    user: {
        fields?: {
            changepassword?: string;
            specialty?: string;
            instituicao?: string;
            state?: string;
            city?: string;
            program?: string;
        },
        terms?: {},
        name?: string;
        id: string;
        avatar?:string;
        createdAt: number;
        updatedAt: number;
        eventKey: string;
        eventId: string;
        email: string;
    },
    token: string;
}



type ContextType = {
    userData: UserData;
    setUserData: (value: UserData) => void;
    userSaved: boolean;
    setUserSaved: (value: boolean) => void;
};

const ContextApp = createContext<ContextType>({
    userData: {} as UserData,
    setUserData: (Value: UserData) => { },
    userSaved: false,
    setUserSaved: (value: boolean) => { },
});

export type { UserData }
const ProviderAuth: React.FC = ({ children }) => {

    const [userSaved, setUserSaved] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserData>({} as UserData);

    

    return (
        <ContextApp.Provider value={{
            userData, setUserData,
            userSaved, setUserSaved,
        }}>
            {children}
        </ContextApp.Provider>
    );
}
export default ProviderAuth;

export function useUserData() {
    const infoUser: ContextType = useContext(ContextApp);
    const { userData, setUserData } = infoUser;
    return { userData, setUserData };
}

export function useUserSaved() {
    const infoUser: ContextType = useContext(ContextApp);
    const { userSaved, setUserSaved } = infoUser;
    return { userSaved, setUserSaved };
}

