import React from 'react';
import ReactDOM from 'react-dom';
import ProviderEvent from './context/eventContext'
import ProviderAuth from './context/authContext'
import Routes from './routes';
import { ToastProvider } from 'react-toast-notifications';
import './css/index.css';
import './i18n'
ReactDOM.render(
  <ToastProvider
    autoDismiss
    autoDismissTimeout={6000}
    placement="bottom-center">
    <ProviderEvent>
      <ProviderAuth>
        <Routes />
      </ProviderAuth>
    </ProviderEvent>
  </ToastProvider>
  ,
  document.getElementById('root')
);

