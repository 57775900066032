import React from "react";

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-center">
                <div className="footer-copyright">
                    <p>© Copyright 2021</p>
                </div>
                <div className="footer-right-menu">
                    {/* <a href="contact.php">Dúvidas? Entre em contato conosco</a> |
                    <a href="www.google.com">Políticas de Privacidade</a> |
                    <a href="ww.google.com">Termos de uso</a> */}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
