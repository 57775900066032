import React from 'react'
import { Button } from '../../Button';
import { useTranslation } from 'react-i18next';
const FormPassword: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <h2 style={{ marginBottom: '30px' }}>{t('login.code')}</h2>
            <form className="login-form" method="post" action="php/site-functions.php?form=register-code">
                <p data-children-count={1}>
                    <input type="text" placeholder="E-mail..." name="code" />
                </p>
                <div className="form-final-line">
                    <div>
                        <Button value={t('login.continue')} href="watch-video" type="submit" />
                    </div>
                </div>
            </form>
        </>
    );
}

export default FormPassword;
