import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api'
import Favicon from 'react-favicon'
interface RegisterFields {
    name: string;
    order: number;
    required: boolean;
    dataList: any[];
    type: string;
}

interface Resources {
    key: string;
    name: string;
    tab: boolean;
    value: any;
}

interface EventDataType {
    registerDomains: string[],
    registerFields: RegisterFields[],
    resources: Resources[],
    isComment: boolean;
    customization: {
        bannerlobby?: string;
        backgroundInternal?: string;
        theme_color: string;
        backgroundHeaderGradientColor1?: string;
        backgroundHeaderGradientColor2?: string;
        inputBackgroundColor?: string;
        logo?: string;
        colorSubTitle: string;
        colorBackSubTitle: string;
        banner?: string;
        backgroundColor?: string;
        buttonBackgroundColor?: string;
        buttonTextColor?: string;
        bannerlp?: string;
        background?: string;
        fontColor?: string;
        infoImage?: string;
        navbarBackground?: string;
        sideBarBackground?: string;
        backgroundHeaderGradient?: object;
        backgroundMobile?: string;
        inputTextColor?: string;
        dateBgColor?: string;
        hourBgColor?: string;
        favicon?:string;
    },
    terms: { description: string }[],
    id: string;
    createdAt: number;
    isCertificate: boolean;
    updatedAt: number;
    name: string;
    clientId: string;
    key: string;
    titleSubTitle?: {
        en: string;
        pt:string;
        es: string;
    };
    isAvailablePassword: boolean;
    active: boolean;
    isFixedPassword: boolean

}

type ContextType = {
    beLoaded: boolean;
    setBeLoaded: (value: boolean) => void;
    eventData: EventDataType;
    setEventData: (value: EventDataType) => void;
    isCertificate: boolean;
    setIsCertificate: (value: boolean) => void;

};

const ContextApp = createContext<ContextType>({
    beLoaded: false,
    setBeLoaded: (value: boolean) => { },
    eventData: {} as EventDataType,
    setEventData: (value: EventDataType) => { },
    isCertificate: false,
    setIsCertificate: (value: boolean) => { },


});

const ProviderEvent: React.FC = ({ children }) => {
    const [beLoaded, setBeLoaded] = useState<boolean>(false);
    const [eventData, setEventData] = useState<EventDataType>({} as EventDataType)
    const [isCertificate, setIsCertificate] = useState<boolean>(false)
    return (
        <ContextApp.Provider value={{
            beLoaded, setBeLoaded,
            eventData, setEventData,
            isCertificate, setIsCertificate
        }}>
            {children}
        </ContextApp.Provider>
    );
}
export default ProviderEvent;


export function useBeLoaded() {
    const infoUser: ContextType = useContext(ContextApp);
    const { beLoaded, setBeLoaded } = infoUser;
    return { beLoaded, setBeLoaded };
}

export function useEventData() {
    const infoUser: ContextType = useContext(ContextApp);
    const { eventData, setEventData } = infoUser;
    return { eventData, setEventData };
}


export function useCertificate() {
    const infoUser: ContextType = useContext(ContextApp);
    const { isCertificate, setIsCertificate } = infoUser;
    return { isCertificate, setIsCertificate };
}