import React from "react";
import { useEventData } from '../../../context/eventContext'
import { useUserData, useUserSaved } from '../../../context/authContext'
import api from '../../../services/api'
import { useParams, useHistory } from "react-router-dom";
import { useInterval } from 'beautiful-react-hooks'
interface Props {
  pageContainer: Boolean;
  isBackground?: boolean;
}

const Container: React.FC<Props> = ({ children, pageContainer, isBackground }) => {
  const { eventData } = useEventData()
  const history = useHistory()
  const { eventKey } = useParams<{ eventKey: string }>()
  const { userData, setUserData } = useUserData()
  const { userSaved, setUserSaved } = useUserSaved()
  const config = {
    headers: { Authorization: `Bearer ${userData.token}` }
  }
  const keepAlive = () => {
    console.log('keepp live')
    const dataUser = userData
    api.get(`/sessions/keep-alive/${dataUser.user.id}/${dataUser.user.eventId}`, config).then(ress => {
      if (ress.data?.token) {
        setUserData({ user: dataUser.user, token: ress.data.token })
      } else {
       console.log('keep live')
      }
    }).catch((err => {
      console.log(err)
      console.log('keep live')
    }))
  }

  useInterval(() => {
    // execute only when has a loged user
    if (userSaved) keepAlive();

  }, 30000);
  return (
    <div style={{ backgroundImage: isBackground ? `url(${eventData.customization.bannerlobby})` : '' }} className={pageContainer ? 'page-container login-page-container' : 'page-container'}>
      {children}
    </div>
  );
}

export default Container;
