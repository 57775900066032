import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FormPassword from '../../components/FormsContainers/FormPassword';
import MainContainer from '../../components/UI/MainContainer';
import TitleContainer from '../../components/UI/TitleContainer';
import Container from '../../components/UI/Container';
import { useEventData } from '../../context/eventContext'
const ForgotPassword: React.FC = () => {
    const { eventData } = useEventData()
    return (
        <Container pageContainer={true}>

            <Header />
            <TitleContainer><span style={{ color: eventData.customization.fontColor }}>{eventData.name}</span></TitleContainer>

            <MainContainer MainContainer={true}>
                <div style={{ display: 'block', maxWidth: '1000px', margin: '0px auto' }}>
                    <FormPassword />
                </div>
            </MainContainer>

            <Footer />
        </Container>
    );
}

export default ForgotPassword;
